<template>
  <Button :disabled="disabled" type="error" @click="clickDeleteHb">
    删除
    <Modal title="请输入删除原因备注" v-model="modal">
      <Input v-model="delText" :maxlength="50" />
      <span>{{delText.length}}/50</span>
      <template slot="footer">
        <Button type="text" @click="modal=false">取消</Button>
        <Button :disabled="!(delText.trim())" type="primary" @click="deleteHb">确定</Button>
      </template>
    </Modal>
  </Button>
</template>

<script>
import homePageService from "../../../api/homePageService";
export default {
  props: {
    hb: {},
    disabled: {},
    afterDelCallback: {}
  },
  data() {
    return {
      modal: false,
      delText: ""
    };
  },
  methods: {
    clickDeleteHb() {
      this.modal = true;
    },
    deleteHb() {
      this.hb.remark = this.delText;
      homePageService.deleteHb(this.hb, () => {
        this.$parent.$data.isDropTask = false;
        if (typeof this.afterDelCallback === "function") {
          this.afterDelCallback();          
        }
      });
    }
  }
};
</script>

<style>
</style>